/* eslint-disable linebreak-style */
import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';
import ProtectedRoute from './ProtectedRoute';
import SessionHOC from '../components/hoc/SessionHOC';
import Dashboard from '../components/pages/Dashboard';
import RequestManager from '../components/pages/RequestManager';
import GeneralRequests from '../components/pages/GeneralRequests';
import MeetingRequest from '../components/pages/MeetingRequest';
import MeetingManager from '../components/pages/MeetingManager';
import CreateRequest from '../components/pages/CreateRequest';
import MemoManager from '../components/pages/MemoManager';
import AllMemos from '../components/pages/AllMemos';
import ReviewMemos from '../components/pages/ReviewMemos';
import PrintMemos from '../components/pages/PrintMemos';

export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/newschedule-manager/"
      exact
      component={SessionHOC(Dashboard)}
    />
    <ProtectedRoute
      path="/newschedule-manager/create-request"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/newschedule-manager/update-request/:requestId"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/newschedule-manager/update-meeting/:meetingId"
      exact
      component={SessionHOC(CreateRequest)}
    />
    <ProtectedRoute
      path="/newschedule-manager/requests"
      exact
      component={SessionHOC(GeneralRequests)}
    />
    <ProtectedRoute
      path="/newschedule-manager/request-manager"
      exact
      component={SessionHOC(RequestManager)}
    />
    <ProtectedRoute
      path="/newschedule-manager/meeting-request"
      exact
      component={SessionHOC(MeetingRequest)}
    />
    <ProtectedRoute
      path="/newschedule-manager/meeting-manager"
      exact
      component={SessionHOC(MeetingManager)}
    />
    <ProtectedRoute
      path="/newschedule-manager/Memo-manager"
      exact
      component={SessionHOC(MemoManager)}
    />
    <ProtectedRoute
      path="/newschedule-manager/all-memos"
      exact
      component={SessionHOC(AllMemos)}
    />
    <ProtectedRoute
      path="/newschedule-manager/review-memos"
      exact
      component={SessionHOC(ReviewMemos)}
    />
    <ProtectedRoute
      path="/newschedule-manager/print-all-memos"
      exact
      component={SessionHOC(PrintMemos)}
    />

    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
