import React, { useState, useContext, useEffect } from "react";
import paths from "../../api-services/endpoints";
import {
  Table,
  Pagination,
  Modal,
  Tooltip,
  Popconfirm,
  Button,
  Spin,
  Dropdown,
  Badge,
  Input,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";
import { logout } from "../../utility/auth";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { BsFillCircleFill, BsFillChatFill } from "react-icons/bs";
// import { IoChatbubbleOutline   } from 'react-icons/io5'
import { TiPencil } from "react-icons/ti";
import { AiFillCaretDown } from "react-icons/ai";
// import {ModalContext} from './Contexts/ModalContext'
// import { SwapTableContext } from './Contexts/SwapTableContext'
// import IsLoading from '../assets/IsLoading';

// import notification from '../config/notification';
import notification from "../../utility/notification";

import { BrowserRouter, useLocation, Link } from "react-router-dom";
import ApproveDeclineMemos from "./ApproveDeclineMemos";
import AssignedMemoz from "./AssignedMemos";
import Cookies from "js-cookie";

const ReviewMemos = (prop) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [assignedMemos, setAssignedMemos] = useState([]);

  const [queryFilter, setqueryFilter] = useState(null);
  const [isEdit5FVisible, setIsEdit5FVisible] = useState(false);
  const [holdPageNumber, setholdPageNumber] = useState(1);
  const [isEdit5FVisible3, setIsEdit5FVisible3] = useState(false);
  const [draftStatus, setDraftStatus] = useState(0);
  const [refresh, setrefresh] = useState(0);
  const [isSpin, setIsSpin] = useState(true);
  let [actionedMemos, setActionedMemos] = useState([]);
  let [actionedMemosLength, setActionedMemosLength] = useState(0);
  let [assignedMemosLength, setAssignedMemosLength] = useState(0);

  let [pendingMemos, setPendingMemos] = useState([]);
  let [pendingMemosLength, setPendingMemosLength] = useState(0);

  let [paginationLength, setPaginationLength] = useState(10);

  let [valuez, setvaluez] = useState("");
  let [buttonName, setButtonName] = useState("");
  let [typedValue, setTypedValue] = useState("");
  let [sucess, setSucess] = useState("");
  let [content, setContent] = useState(false);
  let [OnPending, setOnPending] = useState(true);
  let [OnTreated, setOnTreated] = useState(false);
  let [OnAssigned, setOnAssigned] = useState(false);
  let [data1, setData1] = useState([]);

  // let [modulename, setModulename] = useState("")

  const [pendingApprovals, setPendingApprovals] = useState([]);

  let [holdAssignedMemos, setHoldAssignedMemos] = useState([]);
  let [data2, setData2] = useState([]);
  let [toAssigned, setToAssigned] = useState(false);

  console.log(currentPath);
  // let [loading, setLoading] = useState('')

  useEffect(() => {
    // alert(queryFilter)
    //   console.log(prop.modulename)
    if (OnTreated) {
      getActionedMemo(1, 10, queryFilter);
    } else if (OnPending) {
      getPendingApprovals(1, 10, queryFilter);
    } else if (OnAssigned) {
      // console.log(queryFilter)
      getAssignedMemo(1, 10, queryFilter);
      // searchAssignedMemo(queryFilter);
    } else return;
    // mapper()
  }, [queryFilter]);

  useEffect(() => {
    //   console.log(prop.modulename)

    if (Cookies.get("requestId")) {
      getFromDashboardMemo(parseInt(Cookies.get("requestId")));
    }
    getPendingApprovals(holdPageNumber, 10, queryFilter);
    getActionedMemo(holdPageNumber, 10, queryFilter);
    getAssignedMemo(holdPageNumber, 10, queryFilter);
    // getAssignedMemo();
    mapper();
    mapper2();
  }, [refresh]);

  useEffect(() => {
    //   console.log(prop.modulename)
    //   getPendingApprovals()
    mapper();
    // mapper2()
  }, [pendingApprovals]);
  useEffect(() => {
    //   console.log(prop.modulename)
    //   getPendingApprovals()
    // mapper()
    mapper2();
  }, [assignedMemos]);

  const varyPaginationLengthPendingApproval = () => {
    setPaginationLength(pendingMemosLength);
  };
  const varyPaginationLengthTreatedMemos = () => {
    setPaginationLength(actionedMemosLength);
  };
  const varyPaginationLengthAssignedMemos = () => {
    setPaginationLength(assignedMemosLength);
  };

  const getFromDashboardMemo = async (id) => {
    const res = await get({
      endpoint: paths.specificMemo,
      pQuery: { Id: id },
      auth: true,
    });

    console.log(res.data.data);
    setContent(res?.data?.data);
    res?.data?.data && setPreview(!preview);
  };

  const getPendingApprovals = async (pageNumber, pageSize, queryFilter) => {
    // let status = 1
    let status = 4;
    let payload = {
      pageNumber,
      pageSize,
      queryFilter,
    };

    const res = await post({
      endpoint: `/api/Memo/pending-approvals`,
      body: payload,
      auth: true,
    });
    console.log(res);
    setPendingMemosLength(
      JSON?.parse(res?.headers["x-pagination"])?.TotalCount
    );
    setPaginationLength(JSON?.parse(res?.headers["x-pagination"])?.TotalCount);

    // const res = await get({ endpoint: `/api/Memo/pending-approvals`, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setPendingApprovals(data.data);
        OnPending && setPendingMemos(data.data);
        // setPaginationLength()
        setIsSpin(false);

        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Approvals",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  //   const handleOk = () => {
  //     // setIsModalVisible(false);
  //   };

  const getActionedMemo = async (pageNumber, pageSize, queryFilter) => {
    let status = 4;
    // let status = 4
    let payload = {
      pageNumber,
      pageSize,
      queryFilter,
    };

    const res = await post({
      endpoint: `/api/Memo/actioned-memos`,
      body: payload,
      auth: true,
    });
    console.log(res);
    setActionedMemosLength(
      JSON?.parse(res?.headers["x-pagination"])?.TotalCount
    );
    // const res = await get({ endpoint: `/api/Memo/actioned-memos`, auth: true });

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setActionedMemos(data.data);
        OnTreated && setPendingApprovals(data.data);
        setIsSpin(false);
        // setActionedMemosLength()
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  const getAssignedMemo = async (pageNumber, pageSize, queryFilter) => {
    let payload = {
      pageNumber,
      pageSize,
      queryFilter,
    };

    // let status = 4
    const res = await get({
      endpoint: `/api/Memo/assigned-memos?page=${pageNumber}&pageSize=${pageSize}`,
      body: payload,
      auth: true,
    });

    setAssignedMemosLength(res?.data?.totalCount);
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setAssignedMemos(data.data);
        setHoldAssignedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const pagOnChange = (page, pageSize) => {
    setIsSpin(true);
    setholdPageNumber(page);

    if (OnTreated) {
      getActionedMemo(page, pageSize, queryFilter);
    }
    if (OnPending) {
      getPendingApprovals(page, pageSize, queryFilter);
    } else if (OnAssigned) {
      getAssignedMemo(page, pageSize, queryFilter);
    }
    // toPostPend(page,pageSize)

    // console.log
  };

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "25%",
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   key: 'category',
    // },
    // {
    //   title: 'Details',
    //   key: 'details',
    //   dataIndex: 'details',
    //   width:"40%"
    // },
    {
      title: "Initiated By",
      key: "initiatedBy",
      dataIndex: "initiatedBy",
    },
    {
      title: "Date created",
      key: "dateCreated",
      dataIndex: "dateCreated",
    },
    {
      title: "Status",
      // key: 'status',
      dataIndex: "status",
      render: (text, record) => {
        // if (record.status == 'approved')
        console.log(record.status);
        return (
          <div className="flex-align">
            {/* <BsFillCircleFill color= {record?.status == 'approved'?"green":record.status == 'Pending'?'#FDCC29':record.status == 'Assigned'?'blue':'red'} style={{marginRight: "5px"}} /> */}
            {/* <p className="no-margin" >{record.status?.toUpperCase()}</p> */}
            <BsFillCircleFill
              color={
                record.isAssigned
                  ? "grey"
                  : record?.status == "approved"
                  ? "green"
                  : record.status == "Pending"
                  ? "#FDCC29"
                  : record.status == "Assigned"
                  ? "blue"
                  : "red"
              }
              style={{ marginRight: "5px" }}
            />
            <p className="no-margin">
              {record.isAssigned
                ? "Sent for Review"
                : record.status?.toUpperCase()}
            </p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return record.repliesCount && record.repliesCount > 0 ? (
          <Tooltip
            title={
              record.repliesCount < 2
                ? `You have ${record.repliesCount} Replied message`
                : `You have ${record.repliesCount} Replied messages`
            }
            color="cyan"
          >
            <Badge
              count={
                <div>
                  <span
                    className="pointer"
                    style={{
                      position: "relative",
                      left: record.repliesCount < 10 ? "12px" : "14px",
                      bottom: "6px",
                      color: "white",
                      fontSize: "9px",
                    }}
                  >
                    {record.repliesCount}
                  </span>
                  <BsFillChatFill
                    color={"red"}
                    style={{ backgroundColor: "", fontSize: "20px" }}
                  />
                </div>
              }
            >
              <Button
                onClick={() => {
                  showPreview();
                  setContent(record.modal);
                  console.log(record);
                }}
                style={{
                  backgroundColor: "#1C811C",
                  justifyContent: "space-between",
                  border: "none",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "5px", backgroundColor: "" }}>
                  View Details
                </div>
              </Button>
            </Badge>
          </Tooltip>
        ) : (
          <Button
            onClick={() => {
              showPreview();
              setContent(record.modal);
              console.log(record);
            }}
            style={{
              backgroundColor: "#1C811C",
              justifyContent: "space-between",
              border: "none",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px", backgroundColor: "" }}>
              View Details
            </div>
          </Button>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "25%",
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   key: 'category',
    // },
    // {
    //   title: 'Details',
    //   key: 'details',
    //   dataIndex: 'details',
    //   width:"40%"
    // },
    {
      title: "Initiated By",
      key: "initiatedBy",
      dataIndex: "initiatedBy",
    },
    {
      title: "Date created",
      key: "dateCreated",
      dataIndex: "dateCreated",
    },
    {
      title: "Status",
      // key: 'status',
      dataIndex: "status",
      render: (text, record) => {
        // if (record.status == 'approved')
        // prop?.contents?.replies&&prop?.contents?.replies?.length!==0?
        return (
          <div className="flex-align">
            <BsFillCircleFill
              color={
                record.replies?.length !== 0
                  ? "grey"
                  : record?.status == "approved"
                  ? "green"
                  : record.status == "Pending"
                  ? "#FDCC29"
                  : record.status == "Pending Review"
                  ? "blue"
                  : "red"
              }
              style={{ marginRight: "5px" }}
            />
            <p className="no-margin">
              {
                record.replies?.length ? "REVIEWED" : "PENDING REVIEW"

                // record.status?.toUpperCase()
              }
            </p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return record.repliesCount && record.repliesCount > 0 ? (
          <Tooltip
            title={
              record.repliesCount < 2
                ? `You have ${record.repliesCount} Replied message`
                : `You have ${record.repliesCount} Replied messages`
            }
            color="cyan"
          >
            <Badge
              count={
                <div>
                  <span
                    className="pointer"
                    style={{
                      position: "relative",
                      left: record.repliesCount < 10 ? "12px" : "14px",
                      bottom: "6px",
                      color: "white",
                      fontSize: "9px",
                    }}
                  >
                    {record.repliesCount}
                  </span>
                  <BsFillChatFill
                    color={"red"}
                    style={{ backgroundColor: "", fontSize: "20px" }}
                  />
                </div>
              }
            >
              <Button
                onClick={() => {
                  showPreview();
                  setContent(record.modal);
                  console.log(record);
                }}
                style={{
                  backgroundColor: "#1C811C",
                  justifyContent: "space-between",
                  border: "none",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "5px", backgroundColor: "" }}>
                  View Details
                </div>
              </Button>
            </Badge>
          </Tooltip>
        ) : (
          <Button
            onClick={() => {
              showPreview();
              setContent(record.modal);
              console.log(record);
            }}
            style={{
              backgroundColor: "#1C811C",
              justifyContent: "space-between",
              border: "none",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px", backgroundColor: "" }}>
              View Details
            </div>
          </Button>
        );
      },
    },
  ];

  // const {handleShow6} = useContext(ModalContext)
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleCancel = async () => {
    setrefresh(refresh + 1);
    showPreview(!preview);
    if (Cookies.get("requestId")) {
      await logout();
      Cookies.remove("requestId");
      Cookies.remove("fromDashboard");

      return;
    }

    // setPropz('')
    // setRefresh(refresh+1)
  };

  const handleCancel2 = async () => {
    if (Cookies.get("requestId")) {
      await logout();
      Cookies.remove("requestId");
      Cookies.remove("fromDashboard");

      return;
    }

    getPendingApprovals(holdPageNumber, 10);
    getActionedMemo(holdPageNumber, 10);
    getAssignedMemo(holdPageNumber, 10, queryFilter);
    // getAssignedMemo();
    mapper();
    showPreview(!preview);
    // setPropz('')
    setrefresh(refresh + 1);

    // onReset()
    // setSubject('')
  };
  const showPreview = () => {
    setPreview(!preview);
    // setPropz('preview')
  };

  const mapper = () => {
    let data = pendingApprovals?.map((res) => ({
      key: res?.id,
      initiatedBy: res?.createdBy,
      subject: res?.subject,
      approvers: res?.memoRequestApprovers,
      details: res?.details,
      documents: res?.memoRequestDocuments,
      dateCreated: res?.createdAt && moment(res.createdAt).format("LL"),
      status: res?.status,
      isAssigned: res?.isAssigned,
      repliesCount: res?.repliesCount,
      urgency: res.urgency,

      modal: res,
    }));
    console.log(data);
    setData1(data);
  };

  const mapper2 = () => {
    console.log(assignedMemos);
    const dataz = assignedMemos?.map((res) => ({
      key: res?.memoRequest?.id,
      initiatedBy: res?.memoRequest?.fromName,
      subject: res?.memoRequest?.subject || res.title,
      approvers: res?.memoRequest?.memoRequestApprovers,
      details: res?.memoRequest?.details || res.description,
      urgency: res.urgency,
      documents: res?.memoRequest?.memoRequestDocuments,
      dateCreated:
        res?.memoRequest?.createdAt &&
        moment(res?.memoRequest?.createdAt).format("LL"),
      status: "Pending Review",
      // status: res?.memoRequest?.status,
      modal: res,
      replies: res.replies,
    }));
    setData2(dataz);
  };

  const searchAssignedMemo = (word) => {
    console.log(word);
    console.log(assignedMemos);
    // let jj = assignedMemos[0].memoRequest.fromName
    // console.log(jj)

    let p = holdAssignedMemos.filter(
      (pp) =>
        // !jj.includes(word)
        pp?.memoRequest?.fromName
          ?.toUpperCase()
          ?.includes(word?.toUpperCase()) ||
        pp?.memoRequest?.subject?.toUpperCase()?.includes(word?.toUpperCase())
    );
    console.log(p);
    // setAssignedMemos(p)
    let dataz = p?.map((res) => ({
      key: res?.memoRequest?.id,
      initiatedBy: res?.memoRequest?.fromName,
      subject: res?.memoRequest?.subject || res.title,
      approvers: res?.memoRequest?.memoRequestApprovers,
      details: res?.memoRequest?.details || res.description,
      documents: res?.memoRequest?.memoRequestDocuments,
      dateCreated:
        res?.memoRequest?.createdAt &&
        moment(res?.memoRequest?.createdAt).format("LL"),
      status: "Assigned",
      // status: res?.memoRequest?.status,
      modal: res,
      replies: res.replies,
    }));
    console.log(dataz);

    setData2(dataz);
  };
  return (
    <div className="w-100">
      <h3 className="bold-text">Review Memos</h3>

      <div className="flex">
        <div
          onClick={() => {
            setToAssigned(false);
            setqueryFilter("");
            varyPaginationLengthPendingApproval();
            setPendingApprovals(pendingMemos);
            setOnAssigned(false);
            setOnPending(true);
            setOnTreated(false);
          }}
          className="w-100 pointer m-r-10"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Pending Approvals</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{pendingMemosLength}</b>
            </h2>
          </div>
        </div>
        <div
          onClick={() => {
            setToAssigned(false);
            setqueryFilter("");
            varyPaginationLengthTreatedMemos();
            setOnAssigned(false);
            setPendingApprovals(actionedMemos);
            setOnTreated(true);
            setOnPending(false);
          }}
          className="w-100 pointer m-r-10"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Treated Memos</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{actionedMemosLength}</b>
            </h2>
          </div>
        </div>
        <div
          onClick={() => {
            setToAssigned(true);
            mapper2();
            setOnAssigned(true);
            setOnTreated(false);
            setOnPending(false);
            varyPaginationLengthAssignedMemos();
          }}
          className="w-100 pointer m-r-10"
          style={{ borderBottom: "3px solid green" }}
        >
          <div className="mFiles-hover">
            <div
              className="flex-between"
              style={{ padding: "15px", marginBottom: "3px" }}
            >
              <p>Pending Review</p>
              {prop.image}
            </div>
            <h2 style={{ padding: "0 15px" }}>
              <b>{assignedMemosLength}</b>
            </h2>
          </div>
        </div>
      </div>

      <div
        className="flex-align m-b-5 flex-between"
        style={{ width: "23%", marginLeft: "auto", marginRight: "20px" }}
      >
        {/* <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(pendingMemos)}>Pending Approvals</Button> */}

        {/* <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(actionedMemos)}>Treated Memos</Button> */}
      </div>

      {/* <Table
      bordered
       columns={columns} 
       dataSource={data1} 
       /> */}
      {toAssigned && (
        <div style={{ textAlign: "center" }}>
          <Input
            value={queryFilter}
            style={{ width: "50%", marginBottom: "10px", marginTop: "10px" }}
            placeholder="Search for memo"
            onChange={(e) => setqueryFilter(e.target.value)}
          />

          <Table
            pagination={false}
            bordered
            columns={columns2}
            dataSource={data2}
            rowClassName={(record, index) => {
              if (record.urgency === 0) return "highlight-row";
              if (record.urgency === 1) return "urgent";
              if (record.urgency === 2) return "super-urgent";
            }}
          />
          <Pagination
            style={{ marginLeft: "auto", marginTop: "5px", textAlign: "right" }}
            onChange={pagOnChange}
            total={paginationLength}
          />
        </div>
      )}

      {!toAssigned && (
        <div style={{ textAlign: "center" }}>
          <Input
            value={queryFilter}
            style={{ width: "50%", marginBottom: "10px", marginTop: "10px" }}
            placeholder="Search for memo"
            onChange={(e) => setqueryFilter(e.target.value)}
          />

          {!isSpin ? (
            <Table
              pagination={false}
              bordered
              columns={columns}
              dataSource={data1}
              rowClassName={(record, index) => {
                if (record.urgency === 0) return "highlight-row";
                if (record.urgency === 1) return "urgent";
                if (record.urgency === 2) return "super-urgent";
              }}
            />
          ) : (
            <Spin
              size="large"
              spinning={isSpin}
              style={{ textAlign: "center", fontSize: "130px" }}
            />
          )}
          <Pagination
            style={{ marginLeft: "auto", marginTop: "5px", textAlign: "right" }}
            onChange={pagOnChange}
            total={paginationLength}
          />
        </div>
      )}

      <Modal
        width="70%"
        // confirmLoading
        // okButtonProps={onclick=()=>{alert("How far")}}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={preview}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: "-23px" }}>
          {toAssigned ? (
            <AssignedMemoz
              // handleCancel={handleCancel}
              handleCancel2={handleCancel2}
              contents={content}
            />
          ) : (
            <ApproveDeclineMemos
              // handleCancel={handleCancel}
              handleCancel2={handleCancel2}
              contents={content}
              // previewValues = {previewValues}
              // payload = {payload}
              // signature ={signature}
              // save = {propz}
              // treated = {propzApproved}

              p="Edit"
              // modalContent={modalContent}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
export default ReviewMemos;

// const cards = houseProperties.map(item => <div className= "houseMath4" style={{border:""}}><Link to = "/page3">
// <Picture_card card = {item} height = "200px"/>
// </Link> </div> )
