import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  Tag,
  Modal,
  Tooltip,
  Popconfirm,
  Button,
  Menu,
  Dropdown,
  Badge,
  Input,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { BsFillCircleFill, BsFillTrashFill } from "react-icons/bs";
import { TiPencil } from "react-icons/ti";
import { AiFillCaretDown } from "react-icons/ai";
// import {ModalContext} from './Contexts/ModalContext'
// import { SwapTableContext } from './Contexts/SwapTableContext'
// import IsLoading from '../assets/IsLoading';

// import notification from '../config/notification';
import notification from "../../utility/notification";
import moment from "moment";

import { BrowserRouter, useLocation, Link } from "react-router-dom";
// import ApproveDeclineMemos from './ApproveDeclineMemos';

const AllMemos = (prop) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [requestCount, setRequestCount] = useState(0);
  const [isEdit5FVisible, setIsEdit5FVisible] = useState(false);
  const [isEdit5FVisible3, setIsEdit5FVisible3] = useState(false);
  const [draftStatus, setDraftStatus] = useState(0);
  const [requests, setRequests] = useState([{}]);

  let [valuez, setvaluez] = useState("");
  let [buttonName, setButtonName] = useState("");
  let [typedValue, setTypedValue] = useState("");
  let [sucess, setSucess] = useState("");
  let [content, setContent] = useState(false);
  let [pendingMemos, setPendingMemos] = useState([]);
  let [savedMemos, setSavedMemos] = useState([]);
  let [declinedMemos, setDeclinedMemos] = useState([]);
  let [approvedMemos, setApprovedMemos] = useState([]);
  let [actionedMemos, setActionedMemos] = useState([]);
  let [data1, setData1] = useState([]);
  let [refresh, setRefresh] = useState(0);
  // let [modulename, setModulename] = useState("")

  const [pendingApprovals, setPendingApprovals] = useState([]);

  console.log(currentPath);
  // let [loading, setLoading] = useState('')

  useEffect(() => {
    // setData1(prop.data)
    //   console.log(prop.modulename)
    //   getPendingMemo()
    //   getSavedMemo()
    //   getApprovedMemo()
    //   getDeclinedMemo()
    //   getActionedMemo()
    // mapper()
  }, [prop.data]);

  useEffect(() => {
    //   console.log(prop.modulename)
    //   getPendingApprovals()
    mapper();
  }, [prop.data]);

  const showModal = () => {};
  const handleCancel2 = () => {
    showPreview(!preview);
    // setPropz('')
    setRefresh(refresh + 1);
    // onReset()
    // setSubject('')
  };
  const getSavedMemo = async () => {
    let status = 1;
    const res = await get({
      endpoint: `/api/Memo/memos`,
      pQuery: { status: status },
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setSavedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getPendingMemo = async () => {
    let status = 2;
    const res = await get({
      endpoint: `/api/Memo/memos`,
      pQuery: { status: status },
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setPendingMemos(data.data);
        setPendingApprovals(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getDeclinedMemo = async () => {
    let status = 4;
    const res = await get({
      endpoint: `/api/Memo/memos`,
      pQuery: { status: status },
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setDeclinedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getActionedMemo = async () => {
    let status = 4;
    const res = await get({ endpoint: `/api/Memo/actioned-memos`, auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setActionedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getApprovedMemo = async () => {
    let status = 3;
    const res = await get({
      endpoint: `/api/Memo/memos`,
      pQuery: { status: status },
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setApprovedMemos(data.data);
        // setMapPending(data.data)
        //   reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  //   const handleOk = () => {
  //     // setIsModalVisible(false);
  //   };

  const columns = [
    {
      title: "Name of Approver",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   key: 'category',
    // },
    // {
    //   title: 'Details',
    //   key: 'details',
    //   dataIndex: 'details',
    //   width:"40%"
    // },
    {
      title: "Request Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "In Tray",
      key: "inTray",
      dataIndex: "inTray",
      width: "12%",
      render: (text, record) => {
        return (
          <div style={{ width: "", padding: "0 5px", textAlign: "" }}>
            <p className="no margin ">{record.inTray}</p>
            <p
              className="no margin "
              style={{ fontSize: "10px", marginTop: "" }}
            >
              {record.inTrayTime}
            </p>
          </div>
        );
      },
    },
    {
      title: "Out Tray",
      key: "outTray",
      dataIndex: "outTray",
      width: "12%",
      render: (text, record) => {
        return (
          <div style={{ width: "", padding: "0 5px", textAlign: "" }}>
            <p className="no margin ">{record.outTray}</p>
            <p
              className="no margin "
              style={{ fontSize: "10px", marginTop: "" }}
            >
              {record.outTrayTime}
            </p>
          </div>
        );
      },
    },
    {
      title: "Comment",
      key: "comment",
      dataIndex: "comment",
    },
    {
      title: "Signature",
      width: "12%",
      // key: 'status',
      dataIndex: "signature",
      render: (text, record) => {
        return (
          <div style={{ width: "", padding: "0 5px", textAlign: "" }}>
            {!record.signature ? (
              <p
                style={{ width: "10%", textAlign: "right", padding: "0 5px" }}
              ></p>
            ) : (
              <img
                style={{
                  width: "180%",
                  textAlign: "right",
                  marginLeft: "-25px",
                }}
                src={`data:image/jpeg;base64,${record.signature}`}
              />
            )}
            {/* {!record.signature) ? <p style={{ width: "10%", textAlign: "right", padding: "0 5px" }}>No Image</p> :<img style={{ width: "180%", textAlign: "right",border:"2px solid red", marginLeft:"-25px" }} src={`data:image/jpeg;base64,${record.signature}`} />} */}
            {/* <p className='no margin' >{record?.receivedDate && moment(record?.receivedDate).format('LL')}</p> */}
          </div>
        );
      },
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   key: 'action',
    //   render: (text, record) =>{

    //   return <Button
    //     onClick={() => {
    //       showPreview()
    //       setContent(record.modal)
    //       console.log(record)
    //     }

    //     } style={{ backgroundColor: "#1C811C", border: "none", color: "white" }}>View Details</Button>

    //   }

    // },
  ];

  // const {handleShow6} = useContext(ModalContext)
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleCancel = () => {
    showPreview(!preview);
    // setPropz('')
    // setRefresh(refresh+1)
  };
  const showPreview = () => {
    setPreview(!preview);
    // setPropz('preview')
  };

  const mapper = () => {
    let data = prop.data?.map((res) => ({
      key: res?.id,
      initiatedBy: res?.createdBy,
      name: res?.name,
      inTray: res?.receivedDate ? moment(res?.receivedDate).format("L") : "",
      outTray: res?.modifiedAt ? moment(res?.modifiedAt).format("L") : "",
      outTrayTime: res?.modifiedAt ? moment(res?.modifiedAt).format("LT") : "",
      inTrayTime: res?.modifiedAt ? moment(res?.receivedDate).format("LT") : "",
      comment: res?.comments,
      signature: res?.signature64 ? res.signature64 : "",
      status: res?.approvalStatus?.toUpperCase(),
      modal: res,
    }));
    setData1(data);
  };

  console.log(prop.data);
  return (
    <div className="w-100">
      <div className="flex-between w-100 m-b-10 ">
        {/* <div className='border' onClick={()=>setPendingApprovals(savedMemos)}>Saved</div> */}
      </div>

      {/* <div className='flex-align m-b-5 flex-between' style={{width:"23%", marginLeft:"auto",}}>
 <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(pendingMemos)}>Pending Memos</Button>

   <Button className='' style={{backgroundColor:'#1C811C', color:"white", borderRadius:"10px"}} onClick={()=>setPendingApprovals(actionedMemos)}>Treated Memos</Button>
 </div> */}
      <Table
        bordered={false}
        columns={columns}
        dataSource={data1}
        footer={false}
        pagination={false}
      />

      <Modal
        width="70%"
        // confirmLoading
        // okButtonProps={onclick=()=>{alert("How far")}}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={preview}
        onCancel={handleCancel}
      >
        {/* <div style={{marginTop:"-23px",}}>
                        <ApproveDeclineMemos 
                        handleCancel2={handleCancel2} 
                        contents = {content}
                        // previewValues = {previewValues}
                        // payload = {payload}
                        // signature ={signature}
                        // save = {propz}
                        // treated = {propzApproved}
                        
                        p = "Edit"
                            // modalContent={modalContent}
                        />
                    </div> */}
      </Modal>
    </div>
  );
};
export default AllMemos;

// const cards = houseProperties.map(item => <div className= "houseMath4" style={{border:""}}><Link to = "/page3">
// <Picture_card card = {item} height = "200px"/>
// </Link> </div> )
