/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
import React from "react";

import { RiBarChartFill, RiMailSettingsLine } from "react-icons/ri";
import {
  BiEnvelope,
  BiCalendarPlus,
  BiCalendarAlt,
  BiMessageSquareAdd,
} from "react-icons/bi";
import { decodeToken } from "../utility/auth";

const sidebarMenu = () => {
  let isGov = null;
  let isPa = null;

  const ig = localStorage.getItem("ig");
  if (ig) {
    isGov = decodeToken("ig").ig;
  }

  const ip = localStorage.getItem("ip");
  if (ip) {
    isPa = decodeToken("ip").ip;
  }

  console.log("is-Gov: ", ig, isGov, "is-PA: ", ip, isPa);

  const menu = [];

  // (isGov || isPa) &&
  //   menu.push({
  //     title: "Dashboard",
  //     href: "/newschedule-manager",
  //     icon: <RiBarChartFill className="icon" />,
  //   });

  menu.push({
    title: "Dashboard",
    href: "/newschedule-manager/all-memos",
    icon: <BiCalendarPlus className="icon" />,
  });

  menu.push({
    title: "Initiate Memo",
    href: "/newschedule-manager/Memo-manager",
    icon: <BiCalendarPlus className="icon" />,
  });
  menu.push({
    title: "Review Memo",
    href: "/newschedule-manager/review-memos",
    icon: <BiCalendarPlus className="icon" />,
  });
  //  menu.push({
  //    title: "Print all Approved Memos",
  //    href: "/newschedule-manager/print-all-memos",
  //    icon: <BiCalendarPlus className="icon" />,
  //  });

  return menu;
};

export default sidebarMenu;
