import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Tooltip,
  Form,
  Card,
  Pagination,
  Popconfirm,
  message,
  Radio,
} from "antd";
import { get, patch, post, del } from "../../api-services/fetch";
import axios from "axios";
import notification from "../../utility/notification";
import Cookies from "js-cookie";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import ViewGovTable from './ViewGovTable';
import { decodeToken } from "../../utility/auth";
import PreviewScreen from "./PreviewScreen";
import EditScreen from "./EditScreen";
import {
  CloseOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ContainerTwoTone,
} from "@ant-design/icons";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { SwapTableContext } from "../Contexts/SwapTableContext";
import ApproveDeclineMemos from "./ApproveDeclineMemos";
import UploadButton from "../layouts/UploadButton";
import SearchDocument from "../layouts/SearchDocument";
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const MemoManager = (props) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  const [urgency, setUrgency] = useState(0);

  // const [Approvers, setApprovers] = useState([])
  const [employee, setEmployee] = useState([]);
  let [paginationLength, setPaginationLength] = useState(1);
  const [subject, setSubject] = useState("");
  const [userId, setuserId] = useState([]);
  const [newTextArea, setNewTextArea] = useState("");

  const [mdaId, setMdaId] = useState([]);
  const [stats, setStats] = useState(false);
  const [submits, setSubmit] = useState(false);
  const [saves, setSaves] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [reducedPendingRequests, setReducedPendingRequests] = useState([]);
  const [shortPending, setShortPending] = useState(false);
  const [mappending, setMapPending] = useState([]);
  const [mapSaved, setMapSaved] = useState([]);
  const [savedRequests, setSavedRequests] = useState([]);
  const [shortSaved, setShortSaved] = useState(true);
  const [reducedSavedRequests, setReducedSavedRequests] = useState([]);
  const [populatePreview, setPopulatePreview] = useState([]);
  const [propz, setPropz] = useState("");
  const [propzApproved, setPropzApproved] = useState("");
  const [previewValues, setpreviewValues] = useState([]);
  const [payload, setPayload] = useState([]);
  const [signature, setsignature] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [statz, setStatz] = useState(0);
  const [costImplication, setCostImplication] = useState(0);
  const [to, setTo] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isLoading2, setIsloading2] = useState(false);

  const [pendingApprovals, setPendingApprovals] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    // ClassicEditor.create( document.querySelector( '#editor' ), {
    //     removePlugins: [ 'blockQuote' ],
    //     toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList' , 'link' ]
    // } )
    // .catch( error => {
    //     console.log( error );
    // } );

    holdMfilesArray([]);

    // console.log(Cookies.get('_HomeLink'))

    setMdaId(+Cookies.get("mdaId"));

    form.setFieldsValue({
      from: `${Cookies?.get("firstName")} ${Cookies?.get("lastName")}`,
    });
    // form.setFieldsValue({costImplication:`0`})

    // setUsername(`${fn} ${ln}`);
    console.log(Cookies.get("signature"));
    console.log(Cookies.get("firstName"));
    console.log(Cookies.get("lastName"));
    setsignature(Cookies.get("signature"));

    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    setuserId(ui);

    // getEmployeeList();
    // getPendingRequests();
    getSavedRequests(1, 10);
    getPendingApprovals(1, 10);
    setNewTextArea("");
  }, [refresh]);

  useEffect(() => {
    console.log(mappending);
    shortPending
      ? setMapPending(reducedPendingRequests)
      : setMapPending(pendingRequests);
    handleEnployeeSearch("a");
  }, [shortPending]);
  useEffect(() => {
    console.log(mapSaved);
    shortSaved ? setMapSaved(reducedSavedRequests) : setMapSaved(savedRequests);
  }, [shortSaved]);

  const {
    handleImage,
    imageHolder,
    mFilesArray,
    handleMfilesArray,
    holdMfilesArray,
  } = useContext(SwapTableContext);

  const children = [];
  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setEmployee(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  function numberWithCommas(x) {
    if (x.includes(",")) {
      return x
        .replace(/,/g, "")
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  const reducedPending = (e) => {
    let reduced = [];
    if (e?.length > 0 && e?.length < 4) {
      for (let i = 0; i < e?.length; i++) {
        reduced.push(e[i]);
      }
    }

    console.log(reduced);
    setReducedPendingRequests(reduced);
  };

  const reducedSaved = (e) => {
    let reduced = [];
    if (e.length > 0 && e.length < 4) {
      for (let i = 0; i < e.length; i++) {
        reduced.push(e[i]);
      }
    }

    console.log(reduced);
    setReducedSavedRequests(reduced);
  };

  const deleteMemo = async (id) => {
    // alert(id)

    const res = await del({
      endpoint: `/api/Memo/memo`,
      param: id,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setRefresh(refresh + 1);
        setPendingApprovals(data.data);
        // setMapPending(data.data)
        // reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const submitSavedMemo = async (requestId) => {
    // alert(requestId)
    console.log(requestId);

    const res = await post({
      endpoint: `/api/Memo/Submit?requestId=${requestId}`,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        notification({
          title: "Success",
          message: data.message,
          type: "success",
        });
        setRefresh(refresh + 1);
        setPendingApprovals(data.data);
        // setMapPending(data.data)
        // reducedPending(data.data)
        //   setShortPending(!shortPending)
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  const getPendingApprovals = async () => {
    let status = 1;

    const res = await get({
      endpoint: `/api/memo/recent-memos`,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setPendingRequests(data.data);
        setMapPending(data.data);
        reducedPending(data.data);
        setShortPending(!shortPending);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    // console.log(Cookies.get('signature'))
    // console.log(Cookies.get('signature'))
    // form.setFieldsValue({costImplication: `${numberWithCommas(costImplication)}`})
  }, [costImplication]);

  const getSavedRequests = async (pageNumber, pageSize) => {
    let status = 1;
    let payload = {
      pageNumber,
      pageSize,
    };

    const res = await post({
      endpoint: `/api/Memo/memos?status=${status}`,
      body: payload,
      auth: true,
    });
    console.log(res);
    // console.log(res.headers['x-pagination']['TotalCount']);
    // console.log(JSON?.parse(res?.headers['x-pagination']));
    // console.log(res?.headers['x-pagination']);
    // console.log(JSON.parse(res.headers['x-pagination'].'TotalCount');
    // setpendingMemoLength(JSON.parse(res.headers['x-pagination']).TotalCount)
    res.headers["x-pagination"] &&
      setPaginationLength(JSON?.parse(res.headers["x-pagination"])?.TotalCount);

    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setSavedRequests(data.data);
        setMapSaved(data.data);
        reducedSaved(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  //   const toPost = async (payload)=>{

  //     const res = await post({ endpoint:'/api/Memo/memo', body: payload,auth: true });
  //     console.log(res);

  //     if (res && res.status === 200) {
  //       const { data } = res;
  //       if (data.code === 1) {
  // // setEmployee(data.data)
  // notification({
  //     title:data.status,
  //     message: data.message,
  //     type: "success",
  //   });
  //   setSaves(false)
  //   setSubmit(false)

  //       } else {
  //         notification({
  //           title: "Error Getting List of Employees",
  //           message: data.message,
  //           type: "danger",
  //         });
  //         setSaves(false)
  //         setSubmit(false)

  //       }
  //     } else {
  //         setSaves(false)
  //         setSubmit(false)

  //       notification({
  //         title: "Network",
  //         message: "Something has gone wrong. Please, try again.",
  //         type: "danger",
  //       });
  //     }

  //   }

  const showPreview = () => {
    setPreview(!preview);
    // setPropz('preview')
  };
  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    showPreview(!preview);
    setPropz("");
    // setRefresh(refresh+1)
    // holdMfilesArray([])
    setIsloading2(false);
    setIsloading(false);
    // onReset()
  };
  const pagOnChange = (page, pageSize) => {
    // setIsSpin(true)
    // setholdPageNumber(page)

    getSavedRequests(page, pageSize);
  };
  const handleCancel2 = () => {
    showPreview(!preview);
    setPropz("");
    setRefresh(refresh + 1);
    onReset();
    setSubject("");
    holdMfilesArray([]);
    setIsloading2(false);
    setIsloading(false);
  };
  console.log(mFilesArray);

  const onFinish = (values) => {
    console.log(values);
    submits && setIsloading2(true);
    saves && setIsloading(true);
    let costImp = costImplication ? costImplication.replace(/,/g, "") : 0;
    if (isNaN(+costImp))
      notification({
        title: "Cost Implication Error",
        message: `${costImp} is not a valid number.. Please check and input valid number in cost Implication field`,
        type: "danger",
      });
    setStatz(1);
    setpreviewValues(values);
    console.log("Success:", values);
    console.log(userId);
    // previewValues
    let approvers =
      values?.thru && values?.thru?.length !== 0
        ? values?.thru?.map((e, idx) => ({
            id: 0,
            sequence: idx + 1,
            memoRequestsId: 0,
            userId: +e.value,
            actionTaken: "string",
            mdaId: mdaId,
            createdAt: "2022-02-26T18:29:27.222Z",
            modifiedAt: "2022-02-26T18:29:27.222Z",
            createdBy: +userId,
            modifiedBy: +userId,
            isDelete: false,
          }))
        : [];
    let documents =
      mFilesArray.length !== 0
        ? mFilesArray.map((e) => ({
            id: e.id,
            name: e.name,
            path: e.path,
          }))
        : [];

    let poster = {
      // to: +values.to,
      to: +values?.to?.value,
      from: +userId,
      subject: values.subject,
      details: newTextArea,
      urgency: urgency,

      // details: values.details,
      status: submits ? 2 : saves ? 1 : "",
      costImplication: +costImp,
      // status:1,
      documents: documents,
      mdaId: mdaId,
      approvers: approvers,
    };
    console.log(poster);
    setPayload(poster);
    showPreview();

    //   toPost(poster)
    //   saves && toPostSave(poster)
  };

  //   ClassicEditor
  // .create( document.querySelector( '#editor' ), {
  //     toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
  //     heading: {
  //         options: [
  //             { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
  //             { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
  //             { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
  //         ]
  //     }
  // } )
  // .catch( error => {
  //     console.log( error );
  // } );

  let inputTimeout;

  const handleTextAreaChange = (e, editor) => {
    if (inputTimeout) {
      clearTimeout(inputTimeout);
    }
    setTimeout(() => {
      // const data = editor.getData()
      setNewTextArea(editor?.getData());
    }, 200);
  };

  const passPreview = (e, pending) => {
    // alert(pending)
    let approvers = e.approvers?.map((e, idx) => ({
      id: 0,
      sequence: idx + 1,
      memoRequestsId: 0,
      userId: +e.userId,
      actionTaken: "string",
      mdaId: mdaId,
      createdAt: "2022-02-26T18:29:27.222Z",
      modifiedAt: "2022-02-26T18:29:27.222Z",
      createdBy: +userId,
      modifiedBy: +userId,
      isDelete: false,
    }));

    let payload = {
      // to: +values.to,
      to: +e.recipient.id,
      from: +userId,
      subject: e.title,
      details: newTextArea,
      // details: e.description,
      status: 1,
      costImplication: +e.costImplication,
      // status:1,
      documents: [
        {
          id: 0,
          name: "string",
          path: "string",
        },
      ],
      mdaId: mdaId,
      approvers: e.approvers,
    };
    console.log(e);
    // alert(pending)
    setPreview(!preview);
    setPropz(pending);
    setStatz(pending);
    // setPropzApproved(true)

    console.log(e);
    setPopulatePreview(e);
  };

  const onInit = (editor) => {
    // editor
    // You can store the "editor" and use when it is needed.
    console.log("Editor is ready to use!", editor);
  };

  const cancel = (e) => {
    console.log(e);
    // message.error("Click on No");
  };

  const switchPending = () => {
    setShortPending(!shortPending);
  };
  const switchSaved = () => {
    setShortSaved(!shortSaved);
  };

  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setUrgency(e.target.value);
  };

  const handleApprovers = (value) => {
    console.log(value);
    //       setApprovers([...Approvers, {
    //         id: 0,
    //         sequence: 0,
    //         memoRequestsId: 0,
    //         userId: 0,
    //         actionTaken: "string",
    //         mdaId: 0,
    //         createdAt: "2022-02-26T18:29:27.222Z",
    //         modifiedAt: "2022-02-26T18:29:27.222Z",
    //         createdBy: 0,
    //         modifiedBy: 0,
    //         isDelete: true
    //       }  ])
  };

  const handleEnployeeSearch = async (text) => {
    const res = await get({
      endpoint: "employeeSearch",
      auth: true,
      pQuery: { q: text || "a" },
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setEmployee(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };
  // Remove a few plugins from the default setup.
  // ClassicEditor
  // .create( document.querySelector( '#editor' ), {
  //     removePlugins: [ 'Heading', 'Link', 'CKFinder' ],
  //     toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ]
  // } )
  // .catch( error => {
  //     console.log( error );
  // } );

  return (
    <div className="w-100">
      <div
        className="p-10  bold-text m-b-20"
        style={{ fontSize: "20px", backgroundColor: "#D5iFFD5" }}
      >
        Memo Manager
      </div>

      <div className="flex w-100">
        <div style={{ width: "70%" }} className=" w-60 m-r-10">
          <div
            style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
            className="p-10"
          >
            <p>
              Memo Subject:{" "}
              <span className="bold-text">{subject?.toUpperCase()}</span>
            </p>
          </div>

          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            // layout='vertical'
            className=" w-100 m-r-10 "
            style={{ backgroundColor: "white", padding: "20px 20px 20px 7px" }}
          >
            <Form.Item
              className="w-100"
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: "Please select who you are sending to!",
                },
              ]}
            >
              <Select
                onChange={(text, index) => {
                  setTo(index?.children);
                }}
                onSearch={(text, index) => {
                  handleEnployeeSearch(text);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                //   mode="multiple"
                labelInValue
                allowClear
                style={{ width: "100%", border: "" }}
                //   onChange={e => this.setState({ details: e.target.value })}
                placeholder="Please select"
                //   defaultValue={['a10', 'c12']}
                //   onChange={handleChange}
              >
                {Array.isArray(employee) &&
                  employee?.map((obj) => (
                    <Option value={obj?.id} key={obj?.id}>{`${obj?.firstName} ${
                      obj?.lastName
                    } ${obj?.role ? obj?.role : ""} ${obj?.mda}`}</Option>
                  ))}
                {/* {children} */}
              </Select>
            </Form.Item>

            <Form.Item className="w-100" label="From" name="from">
              <Input readOnly />
            </Form.Item>

            <Form.Item className="w-100" label="Through" name="thru">
              <Select
                // onChange = {(value)=>handleApprovers(value)}
                onSearch={(text, index) => {
                  handleEnployeeSearch(text);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                mode="multiple"
                labelInValue
                allowClear
                style={{ width: "100%", border: "" }}
                //   onChange={e => this.setState({ details: e.target.value })}
                placeholder="Please select"
                //   defaultValue={['a10', 'c12']}
                //   onChange={handleChange}
              >
                {Array.isArray(employee) &&
                  employee?.map((obj) => (
                    <Option value={obj?.id} key={obj?.id}>{`${obj?.firstName} ${
                      obj?.lastName
                    } ${obj?.role ? obj?.role : ""} ${obj?.mda}`}</Option>
                  ))}
                {/* {children} */}
              </Select>
            </Form.Item>

            <Form.Item
              className="w-100"
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please input Subject!",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>

            <Form.Item
              className="w-100"
              label="Cost NGN"
              name="costImplication"
              // rules={[
              // {
              // required: true,
              // message: 'Please input Cost!',
              // },
              // ]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    costImplication: `${numberWithCommas(e.target?.value)}`,
                  });
                  setCostImplication(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>

            <Form.Item
              className="w-100"
              label="Details"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input Details!",
                },
              ]}
            >
              {/* <TextArea
            className='w-100'
            rows={8}  style={{ width: "100%", border: "" }} /> */}
              <CKEditor
                id="editor"
                editor={ClassicEditor}
                // config = {
                //   toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
                // }
                config={{
                  // removePlugins: [ 'Paragraph' ],
                  removePlugins: ["Link", "CKFinder", "Image", "Media"],
                  // toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
                  // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'TableSelection', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      // "bold",
                      "italic",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "undo",
                      "redo",
                    ],
                  },
                  removeButtons: "Link,Image",
                  // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'tables', 'numberedList', 'blockQuote' ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                    ],
                  },
                }}
                // data = {newTextArea}
                onInit={onInit}
                onChange={handleTextAreaChange}
              />
            </Form.Item>

            <Form.Item
              label="Urgency Status"
              name="urgency"
              // rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Radio.Group
                onChange={onChangeRadio}
                value={urgency}
                defaultValue={0}
                buttonStyle="solid"
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <Radio.Button defaultChecked value={0}>
                    Regular
                  </Radio.Button>
                  <Radio.Button value={1}>Urgent</Radio.Button>
                  <Radio.Button value={2}>Super Urgent</Radio.Button>
                </div>
              </Radio.Group>
            </Form.Item>
            <Form.Item className="flex-align flex-between p-20">
              <div className="bold-text" style={{ marginBottom: "5px" }}>
                {" "}
                Attach Documents
              </div>

              <div className="m-b-10" style={{ width: "50%" }}>
                <UploadButton
                  p="Upload Documents"
                  style={{ marginBottom: "3px" }}
                />
                <div style={{ marginTop: "5px" }}>
                  <SearchDocument />
                </div>
              </div>

              <Button
                loading={isLoading}
                block
                className="m-r-10"
                htmlType="submit"
                onClick={(text, index) => {
                  setSaves(true);
                  setSubmit(false);
                  setPropz("saving");
                }}
              >
                Save Draft
              </Button>
              <Button
                loading={isLoading2}
                block
                className="m-r-10"
                htmlType="submit"
                onClick={(text, index) => {
                  setSubmit(true);
                  setSaves(false);
                  setPropz("submitting");
                }}
              >
                Submit Request
              </Button>
              {/* <Button className='m-r-10' htmlType="submit"  onClick={showPreview}>Preview Draft</Button> */}
            </Form.Item>
          </Form>
        </div>

        <div style={{ width: "40%" }} className="w-40">
          <div
            style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
            className="p-10  m-b-10"
          >
            <p>Recent Memos </p>
          </div>
          <div className="m-b-10">
            {mappending.map((e) => (
              <Card
                onClick={() => {
                  passPreview(e, e?.status);
                }}
                className="no-margin m-b-5 border pointer mFiles-hover"
                hoverable
              >
                <div>
                  <p
                    className="no-margin bold-text"
                    style={{ fontSize: "12px" }}
                  >
                    {e?.title}
                  </p>
                  <p
                    className="no-margin"
                    style={{ fontSize: "10px", opacity: "0.5" }}
                  >
                    {e?.dateReceived && moment(e.dateReceived).format("LL")}
                  </p>
                </div>
              </Card>
            ))}
            <p
              className="p-5 pointer bold-text  mFiles-hover"
              style={{ fontSize: "12px", textAlign: "center" }}
              onClick={() => {
                switchPending();
              }}
            >
              {shortPending ? "View all" : "Collapse"}
            </p>
          </div>
          <div
            style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
            className="p-10  m-b-10"
          >
            <p> Saved Memos</p>
          </div>
          <div className="bg-white">
            {mapSaved?.map((e) => (
              <Card className="no-margin m-b-5 border mFiles-hover" hoverable>
                <div
                  className="flex-between flex-align w-100"
                  style={{ minWidth: "40px" }}
                >
                  <div>
                    <p
                      className="no-margin bold-text"
                      style={{ fontSize: "12px" }}
                    >
                      {e?.title}
                    </p>
                    <p
                      className="no-margin"
                      style={{ fontSize: "10px", opacity: "0.5" }}
                    >
                      {e?.dateReceived && moment(e.dateReceived).format("LL")}
                    </p>
                  </div>
                  <div className="hideHover flex">
                    <Popconfirm
                      style={{ width: "100%" }}
                      title="Are you sure to submit this memo?"
                      onConfirm={() => {
                        submitSavedMemo(e.id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      placement="leftTop"
                    >
                      <Tooltip placement="top" title={"Submit"}>
                        <ContainerTwoTone
                          style={{ fontSize: "20px", marginRight: "5px" }}
                        />
                      </Tooltip>
                    </Popconfirm>
                    <Tooltip placement="top" title={"Edit"}>
                      <EditTwoTone
                        onClick={() => {
                          passPreview(e, 3);
                        }}
                        style={{ fontSize: "20px", marginRight: "5px" }}
                      />
                    </Tooltip>
                    <Popconfirm
                      title="Are you sure to delete this task?"
                      onConfirm={() => {
                        deleteMemo(e.id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      placement="leftTop"
                    >
                      <Tooltip placement="top" title={"Delete"}>
                        <DeleteTwoTone style={{ fontSize: "20px" }} />
                      </Tooltip>
                    </Popconfirm>
                  </div>
                </div>
              </Card>
            ))}
            <p
              className="p-5 pointer bold-text mFiles-hover"
              style={{ fontSize: "12px", textAlign: "center" }}
              onClick={() => {
                switchSaved();
              }}
            >
              {shortSaved ? "View all" : "Collapse"}
            </p>
          </div>
          <Pagination
            size="small"
            style={{ marginLeft: "auto", marginTop: "5px", textAlign: "right" }}
            //  onChange={pagOnChange}
            total={paginationLength}
          />
        </div>
      </div>

      {/* {preview && 
       <div className='overlay'>
           
            <div className="w-60">
                <PreviewScreen/>
            <AiOutlineCloseSquare onClick={()=>{setPreview(false)}} style={{marginLeft:'10px', fontSize:'26px'}}/></div>
            </div>
            } */}

      <Modal
        width="70%"
        // confirmLoading
        // okButtonProps={onclick=()=>{alert("How far")}}
        footer={false}
        // okText={<p style={{ maxWidth: "100px" }}>Boss</p>}
        closeIcon={
          <CloseOutlined
            style={{
              fontSize: "25px",
              backgroundColor: "red",
              color: "white",
              padding: "10px",
              marginLeft: "60px",
            }}
          />
        }
        visible={preview}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: "-23px" }}>
          {statz == "1" ? (
            <PreviewScreen
              handleCancel2={handleCancel2}
              contents={populatePreview}
              to={to}
              preview={propz}
              previewValues={previewValues}
              payload={payload}
              signature={signature}
              // save = {propz}
              // treated = {propzApproved}

              p="Edit"
              // modalContent={modalContent}
            />
          ) : statz == "2" ? (
            <ApproveDeclineMemos
              contents={populatePreview}
              preview={propz}
              previewValues={previewValues}
              payload={payload}
              signature={signature}
              handleCancel2={handleCancel2}
              // save = {propz}
              // treated = {propzApproved}

              p="Edit"
              // modalContent={modalContent}
            />
          ) : (
            <EditScreen
              contents={populatePreview}
              to={to}
              preview={propz}
              previewValues={previewValues}
              payload={payload}
              signature={signature}
              handleCancel2={handleCancel2}
              // save = {propz}
              // treated = {propzApproved}

              p="Edit"
              // modalContent={modalContent}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
// ClassicEditor
// .create( document.querySelector( '#editor' ), {
//     removePlugins: [ 'Heading', 'Link', 'CKFinder' ],
//     toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ]
// } )
// .catch( error => {
//     console.log( error );
// } );

export default MemoManager;
