/* eslint-disable quotes */
/* eslint-disable linebreak-style */
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import ReactNotifications from "react-notifications-component";

import Homepage from "./components/home";

import ProtectedRoute from "./routes/ProtectedRoute";
import AdminPortal from "./components/AdminPortal";
import SwapTableContextProvider from "./components/Contexts/SwapTableContext";
import "antd/dist/antd.css";

function App() {
  const history = createBrowserHistory();

  return (
    <SwapTableContextProvider>
      <Router history={history}>
        <div>
          <ReactNotifications />
          <Switch>
            <Route path="/" exact component={Homepage} />

            <ProtectedRoute path="/newschedule-manager/" component={AdminPortal} />
            <Route render={() => <h1>Error 404. Page not found.</h1>} />
          </Switch>
        </div>
      </Router>
    </SwapTableContextProvider>
  );
}

export default App;
