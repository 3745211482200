import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Select,
  Popconfirm,
  Form,
  Typography,
  Radio,
} from "antd";
import { get, patch, post } from "../../api-services/fetch";
import axios from "axios";
import parse from "html-react-parser";

import notification from "../../utility/notification";
import moment from "moment";
// import ViewGovTable from './ViewGovTable';
// import Cookies from 'js-cookie';
import Festac from "../../assets/images/Festac-logo.jpg";
import EdsgLogo from "../../assets/images/edo-logo-250.png";
import { decodeToken } from "../../utility/auth";
import { SwapTableContext } from "../Contexts/SwapTableContext";
// import PreviewScreen from './PreviewScreen';
import {
  CloseOutlined,
  SearchOutlined,
  PaperClipOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import { AiOutlineCloseSquare } from "react-icons/ai";
import Cookies from "js-cookie";
const BASE_API_URL = "https://edogoverp.com/budget/api/v1";
// const BASE_API_URL = 'http://localhost:5000/api/v1';

// allMdaDrop: '/api/employee/mdas',

const PreviewScreen = (prop) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [preview, setPreview] = useState(false);
  // const [Approvers, setApprovers] = useState([])
  const [employee, setEmployee] = useState([]);
  const [subject, setSubject] = useState("");
  const [userId, setuserId] = useState([]);
  const [submits, setSubmit] = useState(false);
  const [saves, setSaves] = useState(false);
  const [show, setShow] = useState(false);
  const [read, setRead] = useState(true);
  const [costImplication, setCostImplication] = useState();
  const [to, setTo] = useState("");
  const [mdaName, setMdaName] = useState();
  const [mdaAddress, setMdaAddress] = useState();
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isLoading2, setIsloading2] = useState(false);
  const [urgency, setUrgency] = useState(0);

  const [form] = Form.useForm();

  const {
    handleImage,
    imageHolder,
    mFilesArray,
    handleMfilesArray,
    holdMfilesArray,
  } = useContext(SwapTableContext);
  // handleMfilesArray(...prop.contents.documents)

  useEffect(() => {
    // console.log(Cookies.get('signature'))
    // console.log(Cookies.get('signature'))
    prop.preview == "saved" ? setShow(true) : setShow(false);
    // (prop.preview=='saved') ? setRead(false):setRead(true)
    // setUsername(`${fn} ${ln}`);

    console.log(prop.contents);
    console.log(prop.payload);
    console.log(prop?.previewValues);
    console.log(Cookies.get("mdaAddress"));
    let ui = localStorage.getItem("ui");
    ui = decodeToken("ui").ui;
    console.log(ui);
    setuserId(ui);

    if (prop.preview == "saving" || prop.preview == "submitting") {
      form.setFieldsValue({ from: prop?.previewValues?.from });
      form.setFieldsValue({ urgency: prop?.previewValues?.urgency });
      form.setFieldsValue({ to: [prop?.previewValues?.to] });
      form.setFieldsValue({ subject: prop?.previewValues?.subject });
      form.setFieldsValue({ details: prop?.previewValues?.details });
      form.setFieldsValue({ thru: prop?.previewValues?.thru });
      form.setFieldsValue({
        costImplication:
          prop?.previewValues?.costImplication &&
          `N${numberWithCommas(prop?.previewValues?.costImplication)}`,
      });
      setCostImplication(prop?.previewValues?.costImplication);
    } else {
      form.setFieldsValue({ from: prop?.contents?.createdBy });
      form.setFieldsValue({ to: prop?.contents?.recipient?.name });
      form.setFieldsValue({ subject: prop?.contents?.title });
      form.setFieldsValue({ details: prop?.contents?.description });
      form.setFieldsValue({ thru: prop?.contents?.thru });
      form.setFieldsValue({
        costImplication: prop?.contents?.financialImplication,
      });
    }

    setMdaAddress(Cookies?.get("mdaAddress"));
    setMdaName(Cookies?.get("mdaName"));
    setSubject(prop?.contents?.title || prop?.previewValues?.subject);
    getEmployeeList();
  }, [prop?.contents, prop.preview, prop?.previewValues, prop.payloads]);

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const toPost = async (payload) => {
    setIsloading(true);

    const res = await post({
      endpoint: "/api/Memo/memo",
      body: payload,
      auth: true,
    });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        // setEmployee(data.data)
        notification({
          title: data.status,
          message: data.message,
          type: "success",
        });
        setIsloading(false);

        setSaves(false);
        setSubmit(false);

        prop.handleCancel2();
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
        setSaves(false);
        setSubmit(false);
      }
    } else {
      setSaves(false);
      setSubmit(false);

      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const getEmployeeList = async () => {
    const res = await get({ endpoint: "employee", auth: true });
    console.log(res);

    if (res && res.status === 200) {
      const { data } = res;
      if (data.code === 1) {
        console.log(data.data);
        setEmployee(data.data);
      } else {
        notification({
          title: "Error Getting List of Employees",
          message: data.message,
          type: "danger",
        });
      }
    } else {
      notification({
        title: "Network",
        message: "Something has gone wrong. Please, try again.",
        type: "danger",
      });
    }
  };

  const onMount = (values) => {
    // setStatz(1)
    // setpreviewValues(values)
    console.log("Success:", values);
    console.log(userId);
    // previewValues

    let approvers = values.approvers.map((e, idx) => ({
      id: e?.id,
      sequence: e?.sequence,
      memoRequestsId: e?.memoRequestsId,
      userId: +e?.userId,
      actionTaken: e?.actionTaken,
      mdaId: e?.mdaId,
      createdAt: e?.createdAt,
      modifiedAt: e?.modifiedAt,
      createdBy: e?.createdBy,
      modifiedBy: e?.modifiedBy,
      isDelete: e?.isDelete,
    }));

    let poster = {
      id: +values?.id,
      to: +values?.recipient.id,
      from: 247,
      subject: values?.title,
      details: values?.description,
      status: values?.status,
      costImplication: +values?.financialImplication,
      // status:1,
      documents: [
        {
          id: 0,
          name: "string",
          path: "string",
        },
      ],
      mdaId: +values?.mda.id,
      approvers: approvers,
    };
    console.log(poster);
    // setPayload(poster)
    // showPreview()

    //   toPost(poster)
    //   saves && toPostSave(poster)
  };
  const del = (id) => {
    console.log(id);
    console.log(mFilesArray);
    // dataSource.filter((item) => item.key !== key)
    const pp = mFilesArray.filter((x) => x.id !== id);
    holdMfilesArray(pp);
    imageHolder.name = "";
    imageHolder.fileUrl = "";
    handleImage("", "");
  };

  const onFinish = (values) => {
    // if(submits){console.log(prop.payload)

    // prop.payload.status=8}

    // else if (saves){console.log(prop.payload)

    // prop.payload.status=9}

    // else

    // return

    console.log("Success:", values);
    //     console.log(userId)
    //   let approvers = values.thru.map((e,idx)=>(
    //     {
    //         id: 0,
    //         sequence: idx+1,
    //         memoRequestsId: 0,
    //         userId: +e.value,
    //         actionTaken: "string",
    //         mdaId: 0,
    //         createdAt: "2022-02-26T18:29:27.222Z",
    //         modifiedAt: "2022-02-26T18:29:27.222Z",
    //         createdBy: +userId,
    //         modifiedBy: +userId,
    //         isDelete: false
    //       }

    //     ))

    //     let poster =  {
    //         to: values.to,
    //         from: +userId,
    //         subject: values.subject,
    //         details: values.details,
    //         status: submits?1:saves?2:'',
    //         documents: [
    //           {
    //             id: 0,
    //             name: 'string',
    //             path: 'string'
    //           }
    //         ],
    //         mdaId: 11,
    //         approvers:approvers
    //       }
    toPost(prop.payload);
    //   saves && toPostSave(poster)

    // prop.handleCancel2()
  };
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setUrgency(e.target.value);
  };

  return (
    <div className="w-100">
      <div className="flex-between flex-align w-100 p-20">
        <div className="sidebar-header">
          <img src={EdsgLogo} alt="logo" className="brand" width="150" />
        </div>
        <div style={{ textAlign: "center" }}>
          <b>{mdaName}</b>
          <p className="no-margin">{mdaAddress}</p>
        </div>
        <div>
          {/* <p className='bold-text no-margin'>Internal Memo</p>  
  <p className='' style={{fontSize:"10px", opacity:"0.5"}}>August 12 2022</p>   */}
          <img src={Festac} width="40px" />
        </div>
      </div>

      <div className=" w-100">
        <div style={{ width: "100%" }} className=" w-60 m-r-10">
          <div
            style={{ fontSize: "", backgroundColor: "#D5FFD5" }}
            className="p-10"
          >
            <p>
              Memo Subject:{" "}
              <span className="bold-text">{subject?.toUpperCase()}</span>
            </p>
          </div>

          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 3 }}
            // layout='vertical'
            className=" w-100 m-r-10"
            style={{ backgroundColor: "white", padding: "20px 20px 20px 0" }}
          >
            <Form.Item
              className="w-100"
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: "Please select who you are sending to!",
                },
              ]}
            >
              <Select
                readOnly
                bordered={false}
                onChange={(text, index) => {
                  setTo(index?.children);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                //   mode="multiple"
                labelInValue
                allowClear
                style={{ width: "100%", border: "" }}
                //   onChange={e => this.setState({ details: e.target.value })}
                placeholder="Please select"
                //   defaultValue={['a10', 'c12']}
                //   onChange={handleChange}
              >
                {Array.isArray(employee) &&
                  employee?.map((obj) => (
                    <Option readOnly value={obj?.id} key={obj?.id}>{`${
                      obj?.firstName
                    } ${obj?.lastName} ${obj?.role ? obj?.role : ""} ${
                      obj?.mda
                    }`}</Option>
                  ))}
                {/* {children} */}
              </Select>
            </Form.Item>

            <Form.Item className="w-100" label="From" name="from">
              <Input readOnly bordered={false} />
            </Form.Item>

            {(prop?.previewValues?.thru || prop?.contents?.thru) &&
              (prop?.previewValues?.thru?.length !== 0 ||
                prop?.contents?.thru?.length !== 0) && (
                <Form.Item
                  className="w-100"
                  label="Through"
                  name="thru"
                  // rules={[
                  // {
                  // required: true,
                  // message: 'Please input Approvers!',
                  // },
                  // ]}
                >
                  <Select
                    readOnly
                    bordered={false}
                    // onChange = {(value)=>handleApprovers(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    mode="multiple"
                    labelInValue
                    allowClear
                    style={{ width: "100%", border: "" }}
                    //   onChange={e => this.setState({ details: e.target.value })}
                    placeholder="Please select"
                    //   defaultValue={['a10', 'c12']}
                    //   onChange={handleChange}
                  >
                    {Array.isArray(employee) &&
                      employee?.map((obj) => (
                        <Option value={obj.id} key={obj.id}>{`${
                          obj.firstName
                        } ${obj.lastName} ${obj.role ? obj.role : ""} ${
                          obj.mda
                        }`}</Option>
                      ))}
                    {/* {children} */}
                  </Select>
                </Form.Item>
              )}

            <Form.Item
              className="w-100"
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please input Approvers!",
                },
              ]}
            >
              <Input
                readOnly
                bordered={false}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>
            <Form.Item
              className="w-100"
              label="Cost "
              name="costImplication"
              // rules={[
              // {
              // required: true,
              // message: 'Please input Cost!',
              // },
              // ]}
            >
              <Input
                readOnly
                bordered={false}
                onChange={(e) => {
                  setCostImplication(e.target.value);
                }}
                style={{ width: "100%", border: "" }}
              />
            </Form.Item>

            <Form.Item
              className="w-100"
              label="Details"
              name="details"
              rules={[
                {
                  required: true,
                  message: "Please input Approvers!",
                },
              ]}
            >
              <div className="m-t-5">
                {parse(
                  prop?.payload?.details.replaceAll(
                    "<td>",
                    "<td className='border'>"
                  ) ||
                    prop?.contents?.description.replaceAll(
                      "<td>",
                      "<td className='border'>"
                    )
                )}
              </div>

              {/* <TextArea  
            readOnly bordered ={false}
            autoSize={{ minRows: 2, maxRows: 8 }}
            className='w-100'
        style={{ width: "100%", border: "" }} /> */}
            </Form.Item>

            <Form.Item
              label="Urgency Status"
              name="urgency"
              // rules={[{ required: true, message: "Please select an option!" }]}
            >
              <Radio.Group
                // onChange={onChangeRadio}
                // value={urgency}
                defaultValue={0}
                buttonStyle="solid"
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  {prop?.previewValues?.urgency == 0 && (
                    <Radio.Button
                      defaultChecked
                      style={{
                        backgroundColor: "#1890ff",
                        border: "#1890ff",
                        color: "white",
                        font: "bold",
                      }}
                    >
                      Regular
                    </Radio.Button>
                  )}
                  {prop?.previewValues?.urgency == 1 && (
                    <Radio.Button
                      style={{
                        backgroundColor: "yellow",
                        border: "yellow",
                        color: "black",
                        font: "bold",
                      }}
                      value={1}
                    >
                      Urgent
                    </Radio.Button>
                  )}
                  {prop?.previewValues?.urgency == 2 && (
                    <Radio.Button
                      style={{
                        backgroundColor: "red",
                        border: "red",
                        font: "bold",
                      }}
                      value={2}
                    >
                      Super Urgent
                    </Radio.Button>
                  )}
                </div>
              </Radio.Group>
            </Form.Item>

            {prop.preview == "saved" ||
            prop.preview == "preview" ||
            prop.preview == "saving" ||
            prop.preview == "submitting" ? (
              <Form.Item className="flex-align flex-between p-20">
                <div className="">
                  {prop.signature && (
                    <div>
                      <img
                        style={{ width: "160px", height: "70px" }}
                        src={prop?.signature}
                      />
                      <p className="bold-text m-l-20">
                        {" "}
                        {prop?.previewValues?.from}
                      </p>
                    </div>
                  )}

                  <p>Attached Documents</p>

                  {mFilesArray.map((x) => (
                    <div
                      style={{
                        padding: "5px",
                        border: "1px solid ash",
                        marginBottom: "2px",
                      }}
                      // style={{display:(mFilesVal.name=="" ||mFilesVal.name==null||mFilesVal.name==undefined ? "none":"flex"), marginTop:"5px", border:"2px solid red"}}
                      className="flex-between mFiles-hover flex-align"
                    >
                      <div className="flex-align">
                        <PaperClipOutlined />
                        <p style={{ marginLeft: "3px", marginBottom: "0px" }}>
                          {x.name}
                        </p>
                      </div>

                      <div
                        className="pointer"
                        onClick={() => {
                          del(x.id);
                        }}
                      >
                        <DeleteTwoTone style={{ fontSize: "16px" }} />
                      </div>
                    </div>
                  ))}

                  {
                    console.log("Hello:", mFilesArray)
                    //  console.log()
                  }
                </div>

                {(prop.preview == "saving" || prop.preview == "saved") && (
                  <Button
                    loading={isloading}
                    block
                    className="m-r-10"
                    htmlType="submit"
                    onClick={() => {
                      setSaves(true);
                    }}
                  >
                    Save Draft
                  </Button>
                )}
                {(prop.preview == "submitting" || prop.preview == "saved") && (
                  <Button
                    loading={isloading}
                    block
                    className="m-r-10"
                    htmlType="submit"
                    onClick={() => {
                      setSubmit(true);
                    }}
                  >
                    Submit Request
                  </Button>
                )}
                {/* <Button className='m-r-10'  onClick={showPreview}>Preview Draft</Button> */}
              </Form.Item>
            ) : (
              ""
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PreviewScreen;
